
module.exports = class DeliveryOrder {
  constructor (object) {
    this.Id = object.id
    this.CustomerId = object.customerId
    this.ConsumerId = object.consumerId
    this.DeliveryId = object.deliveryId
    this.OrderId = object.OrderId
    this.BranchOfficeId = object.branchOfficeId
    this.PickupAddressId = object.pickupAddressId
    this.DropOffAddressId = object.dropOffAddressId
    this.Status = object.status
    this.DeliveryConfirmedByConsumer = object.deliveryConfirmedByConsumer
    this.DeliveryRatingByConsumer = object.deliveryRatingByConsumer
    this.DeliveryConsumerComments = object.deliveryConsumerComments
  }
}

import $http from '@/plugins/axios'
import { WarehouseError } from '@/lib/Errors'
import Warehouse from '@/models/Warehouse'

export default {
  async getWarehouse () {
    const response = await $http.get('/warehouse')
    if (response.data.resFlag) {
      const userJson = response.data.data.map(warehouse => {
        return new Warehouse(warehouse)
      })
      return userJson
    }

    throw new WarehouseError('Unable to fetch warehouse')
  },
  async getWarehouseById (id) {
    const response = await $http.get(`/warehouse/${id}`)
    if (response.data.resFlag) {
      const userJson = new Warehouse(response.data.data)
      return userJson
    } else {
      throw new WarehouseError('Invalid id')
    }
  },
  async createWarehouse (body) {
    const response = await $http.post('/warehouse', body)
    if (response.data.resFlag) {
      const userJson = response.data
      return userJson
      } else {
      throw new WarehouseError('Error')
    }
  },
  async updateWarehouse (body) {
    const response = await $http.put(`/warehouse/${body.id}`, body)

    if (response.data.resFlag) {
      const userJson = response.data
      return userJson
    } else {
      throw new WarehouseError('Error')
    }
  },
  async deleteWarehouse (id) {
    const response = await $http.delete(`/warehouse/${id}`)

    if (response.data.resFlag) {
      return response.data
    } else {
      throw new WarehouseError('Error deleting the warehouse')
    }
  },
}

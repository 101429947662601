module.exports = class Authentication {
  constructor (object) {
    this.Environment = object.systemEnvironmentName
    this.Session = object.sessionObject
    this.User = object.userObject
    this.BranchOffice = object.branchOfficeData !== null ? object.branchOfficeData : 'No branch office data'
    this.Consumer = object.consumerData !== null ? object.consumerData : 'No branch office data'
    this.Dealer = object.dealerData !== null ? object.dealerData : 'No branch office data'
    this.RegionalOffice = object.regionalOfficeData !== null ? object.regionalOfficeData : 'No branch office data'
  }
}

export const PERMISSIONS = {
  users: {
    list: ['SYSTEM_MASTER', 'SYSTEM_CONTRIBUTOR', 'CUSTOMER_ADMIN', 'CUSTOMER_CONTRIBUTOR', 'REGIONAL_ADMIN', 'BRANCH_ADMIN', 'BRANCH_STAFF', 'WAREHOUSE_ADMIN'],
    edit: ['SYSTEM_MASTER', 'SYSTEM_CONTRIBUTOR', 'CUSTOMER_ADMIN', 'CUSTOMER_CONTRIBUTOR', 'REGIONAL_ADMIN', 'BRANCH_ADMIN', 'BRANCH_STAFF', 'WAREHOUSE_ADMIN'],
    add: ['SYSTEM_MASTER', 'SYSTEM_CONTRIBUTOR', 'CUSTOMER_ADMIN', 'CUSTOMER_CONTRIBUTOR', 'REGIONAL_ADMIN', 'BRANCH_ADMIN', 'BRANCH_STAFF', 'WAREHOUSE_ADMIN'],
    delete: ['SYSTEM_MASTER', 'SYSTEM_CONTRIBUTOR', 'CUSTOMER_ADMIN', 'CUSTOMER_CONTRIBUTOR', 'REGIONAL_ADMIN', 'BRANCH_ADMIN', 'BRANCH_STAFF', 'WAREHOUSE_ADMIN'],
  },
  customers: {
    list: ['SYSTEM_MASTER', 'SYSTEM_CONTRIBUTOR'],
    edit: ['SYSTEM_MASTER'],
    add: ['SYSTEM_MASTER', 'DEALER_ADMIN'],
  },
  consumer: {
    list: ['SYSTEM_MASTER', 'SYSTEM_CONTRIBUTOR'],
    edit: ['SYSTEM_MASTER'],
    add: ['SYSTEM_MASTER'],
  },
  securitycompany: {
    list: ['SYSTEM_MASTER'],
    edit: ['SYSTEM_MASTER'],
    add: ['SYSTEM_MASTER'],
  },
  dealers: {
    list: ['SYSTEM_CONTRIBUTOR', 'CUSTOMER_ADMIN'],
    edit: ['SYSTEM_MASTER', 'CUSTOMER_ADMIN'],
    add: ['SYSTEM_MASTER', 'CUSTOMER_ADMIN'],
  },
  contract: {
    list: ['SYSTEM_MASTER', 'SYSTEM_CONTRIBUTOR'],
    edit: ['SYSTEM_MASTER'],
    add: ['SYSTEM_MASTER'],
  },
  address: {
    list: ['SYSTEM_MASTER', 'SYSTEM_CONTRIBUTOR'],
    edit: ['SYSTEM_MASTER'],
    add: ['SYSTEM_MASTER'],
  },
  verifier: {
    list: ['SYSTEM_MASTER', 'SYSTEM_CONTRIBUTOR'],
    edit: ['SYSTEM_MASTER'],
    add: ['SYSTEM_MASTER'],
  },
  warehouses: {
    list: ['SYSTEM_CONTRIBUTOR', 'CUSTOMER_ADMIN'],
    edit: ['SYSTEM_MASTER', 'CUSTOMER_ADMIN'],
    add: ['SYSTEM_MASTER', 'CUSTOMER_ADMIN'],
  },
  transporter: {
    list: ['SYSTEM_MASTER', 'SYSTEM_CONTRIBUTOR'],
    edit: ['SYSTEM_MASTER'],
    add: ['SYSTEM_MASTER'],
  },
  transporterVehicle: {
    list: ['SYSTEM_MASTER', 'SYSTEM_CONTRIBUTOR'],
    edit: ['SYSTEM_MASTER'],
    add: ['SYSTEM_MASTER'],
  },
  transporterSecurities: {
    list: ['SYSTEM_MASTER', 'SYSTEM_CONTRIBUTOR'],
    edit: ['SYSTEM_MASTER'],
    add: ['SYSTEM_MASTER'],
  },
  reports: {
    list: ['SYSTEM_CONTRIBUTOR', 'CUSTOMER_ADMIN'],
    edit: ['SYSTEM_MASTER', 'CUSTOMER_ADMIN'],
    add: ['SYSTEM_MASTER', 'CUSTOMER_ADMIN'],
  },
  billing: {
    list: ['SYSTEM_CONTRIBUTOR', 'CUSTOMER_ADMIN'],
    edit: ['SYSTEM_MASTER', 'CUSTOMER_ADMIN'],
    add: ['SYSTEM_MASTER', 'CUSTOMER_ADMIN'],
  },
  stores: {
    list: ['CUSTOMER_ADMIN', 'REGIONAL_ADMIN', 'BRANCH_ADMIN'],
    edit: ['CUSTOMER_ADMIN', 'BRANCH_ADMIN', 'REGIONAL_ADMIN'],
    add: ['CUSTOMER_ADMIN', 'REGIONAL_ADMIN'],
    delete: ['CUSTOMER_ADMIN', 'REGIONAL_ADMIN'],
  },
  offers: {
    list: ['CUSTOMER_ADMIN', 'CUSTOMER_CONTRIBUTOR', 'REGIONAL_ADMIN', 'BRANCH_ADMIN', 'WAREHOUSE_ADMIN'],
    edit: ['CUSTOMER_ADMIN', 'REGIONAL_ADMIN', 'BRANCH_ADMIN', 'WAREHOUSE_ADMIN'],
    add: ['CUSTOMER_ADMIN', 'CUSTOMER_CONTRIBUTOR', 'REGIONAL_ADMIN'],
    delete: ['CUSTOMER_ADMIN', 'CUSTOMER_CONTRIBUTOR'],
    assign: ['CUSTOMER_ADMIN', 'CUSTOMER_CONTRIBUTOR', 'REGIONAL_ADMIN'],
  },
  orders: {
    list: ['REGIONAL_ADMIN', 'BRANCH_ADMIN'],
    edit: ['SYSTEM_MASTER', 'REGIONAL_ADMIN', 'BRANCH_ADMIN'],
    add: ['SYSTEM_MASTER', 'REGIONAL_ADMIN', 'BRANCH_ADMIN'],
  },
  staff: {
    list: [],
    edit: ['SYSTEM_MASTER', 'REGIONAL_ADMIN', 'BRANCH_ADMIN'],
    add: ['SYSTEM_MASTER', 'REGIONAL_ADMIN', 'BRANCH_ADMIN'],
  },
  deliveries: {
    list: ['CUSTOMER_ADMIN', 'CUSTOMER_CONTRIBUTOR', 'REGIONAL_ADMIN', 'BRANCH_ADMIN'],
    edit: ['BRANCH_ADMIN'],
    add: ['BRANCH_ADMIN'],
    delete: ['BRANCH_ADMIN'],
  },
  storemonitor: {
    list: ['REGIONAL_ADMIN', 'BRANCH_ADMIN'],
    edit: ['SYSTEM_MASTER', 'REGIONAL_ADMIN', 'BRANCH_ADMIN'],
    add: ['SYSTEM_MASTER', 'REGIONAL_ADMIN', 'BRANCH_ADMIN'],
  },
  regions: {
    list: ['CUSTOMER_ADMIN', 'CUSTOMER_CONTRIBUTOR', 'REGIONAL_ADMIN', 'BRANCH_ADMIN', 'WAREHOUSE_ADMIN'],
    edit: ['CUSTOMER_ADMIN', 'CUSTOMER_CONTRIBUTOR', 'REGIONAL_ADMIN'],
    add: ['CUSTOMER_ADMIN', 'CUSTOMER_CONTRIBUTOR'],
    delete: ['CUSTOMER_ADMIN', 'CUSTOMER_CONTRIBUTOR'],
  },
  settings: {
    list: ['CUSTOMER_ADMIN'],
    edit: ['CUSTOMER_ADMIN'],
    add: ['CUSTOMER_ADMIN'],
  },
}

export function checkPermission (role, type, action) {
  return PERMISSIONS[type][action].includes(role)
}

import Repository from '@/repositories/RepositoryFactory'

const UserRepository = Repository.get('user')

export default {
  namespaced: true,
  state: {
    users: null,
    selectedUser: null,
  },
  mutations: {
    GET_USER (state, payload) {
      state.users = payload
    },
    GET_USER_BY_ID (state, payload) {
      state.selectedUser = payload
    },
  },
  actions: {
    async getUser ({ commit }, data) {
      commit('GET_USER', await UserRepository.getUser())
    },
    async getUserById ({ commit }, id) {
      commit('GET_USER_BY_ID', await UserRepository.getUserById(id))
    },
    async getUserByEmail ({ commit }, email) {
      commit('GET_USER_BY_EMAIL', await UserRepository.getUserByEmailId(email))
    },
    async createUser ({ commit }, data) {
      const res = await UserRepository.createUser(data)
      return res
    },
    async updateUser ({ commit }, body) {
      const res = await UserRepository.updateUser(body)
      return res
    },
    async deleteUser ({ commit }, id) {
      const res = await UserRepository.deleteUser(id)
      return res
    },
    async changepassword ({ commit }, data) {
      const res = await UserRepository.changepassword(data)
      return res
    },
    async changePasswordValidationToken ({ commit }, data) {
      const res = await UserRepository.changePasswordValidationToken(data)
      return res
    },
    async getNotAssociatedCustomerAdmins ({ commit }) {
      const res = await UserRepository.getNotAssociatedCustomerAdmins()
      return res
    },
    async getmyuserprofilephoto ({ commit }) {
      const res = await UserRepository.getmyuserprofilephoto()
      return res
    },
    async uploadmyuserprofilephoto ({ commit }, formData) {
      const res = await UserRepository.uploadmyuserprofilephoto(formData)
      return res
    },
  },
  getters: {
    getFilteredUsers: state => {
      return state.users.filter(u => (u.CustomerId === null && u.RoleName === 'CUSTOMER_ADMIN'))
    },
  },

}

import $http, { requireAuthToken } from '@/plugins/axios'
import { DealerError } from '@/lib/Errors'
import Dealer from '@/models/Dealer'

export default {
  async getDealer () {
    requireAuthToken()
    const response = await $http.get('/dealer')
    if (response.data.resFlag) {
      const userJson = response.data.data.map(dealer => {
        return new Dealer(dealer)
      })
      return userJson
    } else {
      throw new DealerError('Unable to get dealer')
    }
  },

  async getDealerById (id) {
    requireAuthToken()
    const response = await $http.get(`/dealer/${id}`)
    if (response.data.resFlag) {
      // TODO: Create response returns using models
      const userJson = new Dealer(response.data.data)
      return userJson
    } else {
      throw new DealerError('Invalid Id')
    }
  },

  async updateDealer (body) {
    const response = await $http.put(`/dealer/${body.id}`, body)

    if (response.data.resFlag) {
      const userJson = response.data
      return userJson
    } else {
      throw new DealerError('Error on updating the selecting dealer')
    }
  },

  async createDealer (body) {
    const response = await $http.post('/dealer', body)

    if (response.data.resFlag) {
      const userJson = response.data
      return userJson
    } else {
      throw new DealerError('Error on creating dealer')
    }
  },

  async deleteDealer (id) {
    const response = await $http.delete(`/dealer/${id}`)

    if (response.data.resFlag) {
      return response.data
    } else {
      throw new DealerError('Error deleting the dealer')
    }
  },
}

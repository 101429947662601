import Repository from '@/repositories/RepositoryFactory'

const CoiBranchesRepository = Repository.get('coiBranchesPivot')
// coi = customer offered items
export default {
  namespaced: true,
  state: {
    coiBranches: null,
    selectedCoiBranches: null,
  },
  mutations: {
    GET_COI_BRANCHES (state, payload) {
      state.coiBranches = payload
    },
    GET_COI_BRANCHES_BY_ID (state, payload) {
      state.selectedCoiBranches = payload
    },
  },
  actions: {
    async getCoiBranches ({ commit }, data) {
      commit('GET_COI_BRANCHES', await CoiBranchesRepository.getCoiBranches())
    },
    async getCoiBranchesById ({ commit }, id) {
      commit('GET_COI_BRANCHES_BY_ID', await CoiBranchesRepository.getCoiBranchesById(id))
    },
    async createCoiBranches ({ commit }, data) {
      const res = await CoiBranchesRepository.createCoiBranches(data)
      return res
    },
    async updateCoiBranches ({ commit }, data) {
      const res = await CoiBranchesRepository.updateCoiBranches(data)
      return res
    },
    async deleteCoiBranches ({ commit }, id) {
      const res = await CoiBranchesRepository.deleteCoiBranches(id)
      return res
    },
    async deleteCoiByItemAndBranch ({ commit }, data) {
      const res = await CoiBranchesRepository.deleteCoiByItemAndBranch(data)
      return res
    },
  },
  getters: {

  },

}

import Repository from '@/repositories/RepositoryFactory'

const BranchOfficeUserRepository = Repository.get('branchOfficeUser')

export default {
  namespaced: true,
  state: {
    branchofficeuser: null,
  },
  mutations: {
    GET_BRANCH_OFFICE_USER (state, payload) {
      state.branchofficeuser = payload
    },
  },
  actions: {
    async getBranchUser ({ commit }, data) {
      commit('GET_BRANCH_OFFICE_USER', await BranchOfficeUserRepository.getBranchUser())
    },
    async assignuseridbranchofficeid ({ commit }, body) {
      const res = await BranchOfficeUserRepository.assignuseridbranchofficeid(body)
      return res
    },
  },
  getters: {

  },

}

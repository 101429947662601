import $http from '@/plugins/axios'
import { DealerError } from '@/lib/Errors'

export default {
  async createDealerUser (body) {
    const response = await $http.post('/dealeruser', body)
    if (response.data.resFlag) {
      return response.data
    } else {
      throw new DealerError('Error')
    }
  },
  async deleteDealerUser (body) {
    const response = await $http.delete('/dealeruser/deleteuseriddealerid', { data: body })

    if (response.data.resFlag) {
      return response.data
    } else {
      throw new DealerError('Error')
    }
  },
}

import Vue from 'vue'
import Router from 'vue-router'
import { requireAuth } from './authentication'

Vue.use(Router)

const routes = [
  {
    path: '/',
    component: () => import('@/views/dashboard/Index'),
    meta: { requireAuth: true },
    children: [
      {
        name: 'Stores',
        path: '/pages/stores',
        component: () => import('@/views/dashboard/pages/Stores'),
        meta: { requireAuth: true },
      },
      {
        name: 'Regions',
        path: '/pages/regions',
        component: () => import('@/views/dashboard/pages/Regions'),
        meta: { requireAuth: true },
      },
      {
        name: 'Warehouses',
        path: 'pages/warehouses',
        component: () => import('@/views/dashboard/pages/Warehouse'),
      },
      {
        name: 'Dealers',
        path: 'pages/dealers',
        component: () => import('@/views/dashboard/pages/Dealers'),
      },
      {
        name: 'Orders',
        path: 'pages/orders',
        component: () => import('@/views/dashboard/pages/Order'),
      },
      {
        name: 'Notifications',
        path: 'components/notifications',
        component: () => import('@/views/dashboard/component/Notifications'),
      },
      {
        name: 'Billing',
        path: 'pages/billing',
        component: () => import('@/views/dashboard/pages/Billing'),
      },
      {
        name: 'Users',
        path: 'pages/users',
        component: () => import('@/views/dashboard/pages/Users'),
      },
      {
        name: 'Reports',
        path: 'pages/reports',
        component: () => import('@/views/dashboard/pages/Reports'),
      },
      {
        name: 'Offers',
        path: 'pages/offers',
        component: () => import('@/views/dashboard/pages/Offers'),
      },
      {
        name: 'Settings',
        path: 'pages/settings',
        component: () => import('@/views/dashboard/pages/Settings'),
      },
      {
        name: 'Deliveries',
        path: 'pages/deliveries',
        component: () => import('@/views/dashboard/pages/Deliveries'),
      },
      {
        name: 'Customers',
        path: 'pages/customers',
        component: () => import('@/views/dashboard/pages/Customers'),
      },
      {
        name: 'Store monitor',
        path: 'pages/storemonitor',
        component: () => import('@/views/dashboard/maps/GoogleMaps'),
      },
      {
        name: 'Staff',
        path: 'pages/staff',
        component: () => import('@/views/dashboard/pages/Staff'),
      },
      {
        name: 'Google Maps',
        path: 'maps/google-maps',
        component: () => import('@/views/dashboard/maps/GoogleMaps'),
      },
      {
        name: 'Profile',
        path: 'pages/profile',
        component: () => import('@/views/dashboard/pages/Profile'),
      },
    ],
  },
  {
    path: '/auth',
    redirect: '/auth/login',
    component: () => import('@/views/dashboard/Admin/Login'),
    children: [
      {
        path: '/auth/login',
        component: () => import('@/views/dashboard/Admin/Login'),
      },
    ],
  },
  {
    path: '/auth/set_password/:token',
    component: () => import('@/views/dashboard/pages/ForgotPassword'),
    children: [
      {
        path: '/auth/set_password/:token',
        component: () => import('@/views/dashboard/pages/ForgotPassword'),
      },
    ],
  },
  { path: '/:pathMatch(.*)*', redirect: '/' },
]

const router = new Router({
  mode: 'hash',
  routes: routes,
})

router.beforeEach(requireAuth)

export default router

module.exports = class Delivery {
    constructor (object) {
      this.CustomerId = object.customerId !== null ? object.customerId : ''
      this.CustomerName = object.customerName !== null ? object.customerName : ''
      this.DeliveryDriverId = object.deliveryDriverId !== null ? object.deliveryDriverId : ''
      this.DeliveryDriverFullname = object.deliveryDriverFullname !== null ? object.deliveryDriverFullname : ''
      this.BranchOfficeId = object.branchOfficeId !== null ? object.branchOfficeId : ''
      this.BranchOfficeName = object.branchOfficeName !== null ? object.branchOfficeName : ''
      this.Id = object.id !== null ? object.id : ''
      this.Status = object.status !== null ? object.status : ''
    }
  }

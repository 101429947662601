import $http, { requireAuthToken } from '@/plugins/axios'
import { TransporterError } from '@/lib/Errors'
import TransporterMetaData from '@/models/TransporterMetaData'

export default {
  async getTransporterMetaData () {
    requireAuthToken()
    const response = await $http.get('/transportermetadata')

    if (response.data.resFlag) {
      const userJson = response.data.data.map(transporterData => {
        return new TransporterMetaData(transporterData)
      })

      return userJson
    } else {
      throw new TransporterError('error')
    }
  },
  async getTransporterMetaDataById (id) {
    requireAuthToken()
    const response = await $http.get(`/transportermetadata/${id}`)

    if (response.data.resFlag) {
      const userJson = response.data.data
      return userJson
    } else {
      throw new TransporterError('Invalid id')
    }
  },
  async createTransporterMetaData (body) {
    const response = await $http.post('/transportermetadata', body)

    if (response.data.resFlag) {
      const userJson = response.data.data
      return userJson
    } else {
      throw new TransporterError('Error')
    }
  },
  async updateTransporterMetaData (body) {
    const response = await $http.put(`/transportermetadata/${body.id}`, body)

    if (response.data.resFlag) {
      const userJson = response.data.data
      return userJson
    } else {
      throw new TransporterError('Error')
    }
  },
}

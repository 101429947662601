import Repository from '@/repositories/RepositoryFactory'

const ContractRepository = Repository.get('contract')

export default {
  namespaced: true,
  state: {
    contracts: null,
    selectedContract: null,
  },
  mutations: {
    GET_CONTRACT (state, payload) {
      state.contracts = payload
    },
    GET_CONTRACT_BY_ID (state, payload) {
      state.selectedContract = payload
    },
  },
  actions: {
    async getContract ({ commit }, data) {
      commit('GET_CONTRACT', await ContractRepository.getContract())
    },
    async getContractById ({ commit }, id) {
      commit('GET_CONTRACT_BY_ID', await ContractRepository.getContractById(id))
    },
    async createContract ({ commit }, data) {
      const res = await ContractRepository.createContract(data)
      return res
    },
  },
  getters: {

  },

}


const Address = require('./Address')
module.exports = class Customers {
  constructor (object, type = 0) {
    this.Address = typeof object.addressData !== 'undefined' ? new Address(object.addressData) : ''
    this.Name = object.customerName !== null ? object.customerName : ''
    this.AddressId = object.addressId !== null ? object.addressId : ''
    this.AdminId = object.customerAdminId !== null ? object.customerAdminId : ''
    this.ContactPerson = object.contactPerson !== null ? object.contactPerson : ''
    this.ContactPhone = object.contactPhone !== null ? object.contactPhone : ''
    this.ContactEmail = object.contactEmail !== null ? object.contactEmail : ''
    this.MasterOrganization = object.isMasterOrganization !== null ? object.isMasterOrganization : ''
    this.Status = object.status !== null ? object.status : ''
    this.Id = object.id !== null ? object.id : ''
    if (type === 0) this.FormattedAddress = getAddress(object.addressData)
  }
}
const getAddress = (addressData) => {
  return `${addressData.zipCode}, ${addressData.addressLine}, ${addressData.city}, ${addressData.country}`
}

import $http from '@/plugins/axios'
import { WarehouseError } from '@/lib/Errors'

export default {
  async createWarehouseUser (body) {
    const response = await $http.post('/warehouseuser', body)
    if (response.data.resFlag) {
      return response.data
    } else {
      throw new WarehouseError('Error')
    }
  },
  async deleteWarehouseUser (body) {
    const response = await $http.delete('/warehouseuser/deleteuseridwarehouseid', { data: body })

    if (response.data.resFlag) {
      return response.data
    } else {
      throw new WarehouseError('Error')
    }
  },
}

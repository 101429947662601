module.exports = class TransporterSecurities {
  constructor (object) {
    this.CustomerId = object.customerId
    this.UserId = object.userId
    this.SecurityCompanyId = object.securityCompanyId
    this.SecurityType = object.securityType
    this.SecurityDetails = object.securityDetails
    this.SecurityExpires = object.securityExpires
    this.Status = object.status
    this.Id = object.id
  }
}

const Address = require('./Address')
module.exports = class Dealer {
  constructor (object) {
    this.Name = object.dealerDB !== undefined ? object.dealerDB.dealerName : object.dealerName
    this.AddressId = object.dealerDB !== undefined ? object.dealerDB.addressId : object.addressId
    this.Address = object.dealerDB !== undefined ? new Address(object.dealerDB.addressData) : new Address(object.addressData)
    this.FormattedAddress = object.dealerDB !== undefined ? FormattedAddress(object.dealerDB.addressData) : FormattedAddress(object.addressData)
    this.ContactPerson = object.dealerDB !== undefined ? object.dealerDB.contactPerson : object.contactPerson
    this.ContactPhone = object.dealerDB !== undefined ? object.dealerDB.contactPhone : object.contactPhone
    this.ContactEmail = object.dealerDB !== undefined ? object.dealerDB.contactEmail : object.contactEmail
    this.Status = object.dealerDB !== undefined ? object.dealerDB.status : object.status
    this.Id = object.dealerDB !== undefined ? object.dealerDB.id : object.id
    this.CustomerId = object.dealerDB !== undefined ? object.dealerDB.customerId : object.customerId
    if (object.usersList !== undefined) this.UsersList = object.usersList
  }
}

const FormattedAddress = (addressData) => {
  return `${addressData.zipCode}, ${addressData.addressLine}, ${addressData.city}, ${addressData.country}`
}

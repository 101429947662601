import $http, { requireAuthToken } from '@/plugins/axios'
import { CustomerError } from '@/lib/Errors'
import Customers from '@/models/Customers'

export default {
  async getCustomer () {
    requireAuthToken()
    const response = await $http.get('/customer')
    if (response.data.resFlag) {
      // TODO: Create response returns using models
      // const userJson = new Customers(response.data.data)
      const userJson = response.data.data.map(customer => {
        return new Customers(customer)
      })
      return userJson
    } else {
      throw new CustomerError('Invalid username or password')
    }
  },
  async getCustomerById (id) {
    requireAuthToken()
    const response = await $http.get(`/customer/${id}`)

    if (response.data.resFlag) {
        return new Customers(response.data.data)
    } else {
      throw new CustomerError('Invalid id')
    }
  },
  async createCustomer (body) {
    const response = await $http.post('/customer', body)

    if (response.data.resFlag) {
      const userJson = response.data
      return userJson
    } else {
      throw new CustomerError('Error')
    }
  },
  async updateCustomer (body) {
    const response = await $http.put(`/customer/${body.id}`, body)

    if (response.data.resFlag) {
      const userJson = response.data
      return userJson
    } else {
      throw new CustomerError('Error')
    }
  },
}

const Address = require('./Address')
module.exports = class RegionalOffice {
    constructor (object, type = 0) {
      this.Id = object.regionalOfficeDB !== undefined ? object.regionalOfficeDB.id : object.id
      this.CustomerId = object.regionalOfficeDB !== undefined ? object.regionalOfficeDB.customerId : object.customerId
      this.AddressId = object.regionalOfficeDB !== undefined ? object.regionalOfficeDB.addressId : object.addressId
      if (type === 0) this.Address = object.regionalOfficeDB !== undefined ? new Address(object.regionalOfficeDB.addressData) : new Address(object.addressData)
      if (type === 0) this.FormattedAddress = object.regionalOfficeDB !== undefined ? FormattedAddress(object.regionalOfficeDB.addressData) : FormattedAddress(object.addressData)
      this.RegionalOfficeName = object.regionalOfficeDB !== undefined ? object.regionalOfficeDB.regionalOfficeName : object.regionalOfficeName
      this.ContactPerson = object.regionalOfficeDB !== undefined ? object.regionalOfficeDB.contactPerson : object.contactPerson
      this.ContactPhone = object.regionalOfficeDB !== undefined ? object.regionalOfficeDB.contactPhone : object.contactPhone
      this.ContactEmail = object.regionalOfficeDB !== undefined ? object.regionalOfficeDB.contactEmail : object.contactEmail
      this.Status = object.regionalOfficeDB !== undefined ? object.regionalOfficeDB.status : object.status
      if (object.usersList !== undefined) this.UsersList = object.usersList
    }
  }

  const FormattedAddress = (addressData) => {
    return `${addressData.zipCode}, ${addressData.addressLine}, ${addressData.city}, ${addressData.country}`
  }

const Address = require('./Address')
const RegionOffice = require('./RegionalOffice')
module.exports = class BranchOffice {
  constructor (object) {
    this.Id = object.branchOfficeDB !== undefined ? object.branchOfficeDB.id : object.id
    this.CustomerId = object.branchOfficeDB !== undefined ? object.branchOfficeDB.customerId : object.customerId
    this.RegionalOfficeId = object.branchOfficeDB !== undefined ? object.branchOfficeDB.regionalOfficeId : object.regionalOfficeId
    this.RegionOffice = object.branchOfficeDB !== undefined ? new RegionOffice(object.branchOfficeDB.regionalOfficeData, 1) : new RegionOffice(object.regionalOfficeData, 1)
    this.RegionOfficeName = object.branchOfficeDB !== undefined ? object.branchOfficeDB.regionalOfficeData.regionalOfficeName : object.regionalOfficeData.regionalOfficeName
    this.AddressId = object.branchOfficeDB !== undefined ? object.branchOfficeDB.addressId : object.addressId
    this.Address = object.branchOfficeDB !== undefined ? new Address(object.branchOfficeDB.addressData) : new Address(object.addressData)
    this.FormattedAddress = object.branchOfficeDB !== undefined ? FormattedAddress(object.branchOfficeDB.addressData) : FormattedAddress(object.addressData)
    this.BranchOfficeName = object.branchOfficeDB !== undefined ? object.branchOfficeDB.branchOfficeName : object.branchOfficeName
    this.ContactPerson = object.branchOfficeDB !== undefined ? object.branchOfficeDB.contactPerson : object.contactPerson
    this.ContactPhone = object.branchOfficeDB !== undefined ? object.branchOfficeDB.contactPhone : object.contactPhone
    this.ContactEmail = object.branchOfficeDB !== undefined ? object.branchOfficeDB.contactEmail : object.contactEmail
    this.Status = object.branchOfficeDB !== undefined ? object.branchOfficeDB.status : object.status
    if (object.usersList !== undefined) this.UsersList = object.usersList
  }
}

const FormattedAddress = (addressData) => {
  return `${addressData.zipCode}, ${addressData.addressLine}, ${addressData.city}, ${addressData.country}`
}

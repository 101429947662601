import Repository from '@/repositories/RepositoryFactory'

const DeliveryOrderRepository = Repository.get('deliveryOrder')

export default {
  namespaced: true,
  state: {
    deliveryOrders: null,
    selectedDeliveryOrder: null,
  },
  mutations: {
    GET_DELIVERY_ORDER (state, payload) {
      state.deliveryOrders = payload
    },
    GET_DELIVERY_ORDER_BY_ID (state, payload) {
      state.selectedDeliveryOrder = payload
    },
  },
  actions: {
    async getDeliveryOrder ({ commit }, data) {
      commit('GET_DELIVERY_ORDER', await DeliveryOrderRepository.getDeliveryOrder())
    },
    async getDeliveryOrderById ({ commit }, id) {
      commit('GET_DELIVERY_ORDER_BY_ID', await DeliveryOrderRepository.getDeliveryOrderById(id))
    },
    async createDeliveryOrder ({ commit }, data) {
      const res = await DeliveryOrderRepository.createDeliveryOrder(data)
      return res
    },
    async updateDeliveryOrder ({ commit }, body) {
      const res = await DeliveryOrderRepository.updateDeliveryOrder(body)
      return res
    },
    async deleteDeliveryOrder ({ commit }, id) {
      const res = await DeliveryOrderRepository.deleteDeliveryOrder(id)
      return res
    },
  },
  getters: {

  },

}

module.exports = class CoiBranches {
  constructor (object) {
    this.Id = object.id
    this.CustomerId = object.customerId
    this.BranchOfficeId = object.branchOfficeId
    this.AddressId = object.addressId
    this.ItemId = object.itemId
    this.ItemPricePerUnitOverride = object.itemPricePerUnitOverride
    this.Status = object.status
  }
}

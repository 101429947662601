import Repository from '@/repositories/RepositoryFactory'

const RegionalOfficeRepository = Repository.get('regionaloffice')

export default {
  namespaced: true,
  state: {
    regionaloffices: null,
    selectedRegionalOffice: null,
  },
  mutations: {
    GET_REGIONAL_OFFICES (state, payload) {
      state.regionaloffices = payload
    },
    GET_REGIONAL_OFFICES_BY_ID (state, payload) {
      state.selectedRegionalOffice = payload
    },
  },
  actions: {
    async getRegionalOffices ({ commit }, data) {
      commit('GET_REGIONAL_OFFICES', await RegionalOfficeRepository.getRegionalOffices())
    },
    async getRegionalOfficesById ({ commit }, id) {
      commit('GET_REGIONAL_OFFICES_BY_ID', await RegionalOfficeRepository.getRegionalOfficeById(id))
    },
    async createRegionalOffice ({ commit }, data) {
      const res = await RegionalOfficeRepository.createRegionalOffice(data)
      return res
    },
    async updateRegionalOffice ({ commit }, body) {
      const res = await RegionalOfficeRepository.updateRegionalOffice(body)
      return res
    },
    async deleteRegionalOffice ({ commit }, id) {
      const res = await RegionalOfficeRepository.deleteRegionalOffice(id)
      return res
    },
  },
  getters: {

  },

}

import Repository from '@/repositories/RepositoryFactory'

const CustomerOfferedItemsRepository = Repository.get('customerOfferedItems')

export default {
  namespaced: true,
  state: {
    customerOfferedItems: null,
    selectedCustomerOfferedItems: null,
    offerPhoto: null,
  },
  mutations: {
    GET_CUSTOMER_OFFERED_ITEMS (state, payload) {
      state.customerOfferedItems = payload
    },
    GET_CUSTOMER_OFFERED_ITEMS_BY_ID (state, payload) {
      state.selectedCustomerOfferedItems = payload
    },
    GET_OFFER_PHOTO (state, payload) {
      state.offerPhoto = payload
    },
  },
  actions: {
    async getCustomerOfferedItems ({ commit }, data) {
      commit('GET_CUSTOMER_OFFERED_ITEMS', await CustomerOfferedItemsRepository.getCustomerOfferedItems())
    },
    async getCustomerOfferedItemsById ({ commit }, id) {
      commit('GET_CUSTOMER_OFFERED_ITEMS_BY_ID', await CustomerOfferedItemsRepository.getCustomerOfferedItemsById(id))
    },
    async createCustomerOfferedItems ({ commit }, data) {
      const res = await CustomerOfferedItemsRepository.createCustomerOfferedItems(data)
      return res
    },
    async updateCustomerOfferedItems ({ commit }, body) {
      const res = await CustomerOfferedItemsRepository.updateCustomerOfferedItems(body)
      return res
    },
    async deleteCustomerOfferedItems ({ commit }, id) {
      const res = await CustomerOfferedItemsRepository.deleteCustomerOfferedItems(id)
      return res
    },
    async getOfferPhoto ({ commit }, id) {
      commit('GET_OFFER_PHOTO', await CustomerOfferedItemsRepository.getOfferPhoto(id))
    },
    async uploadOfferImage ({ commit }, body) {
      const res = await CustomerOfferedItemsRepository.uploadOfferImage(body)
      return res
    },
  },
  getters: {

  },
}

module.exports = class UserCustomerAdmin {
    constructor (object) {
      this.Id = object.id
      this.Email = object.email
      this.Name = object.firstName
      this.Surname = object.lastName
      this.Phone = object.phone
      this.Status = object.status
      this.Username = object.username
      this.FullName = object.firstName + ' ' + object.lastName
    }
  }

import $http, { requireAuthToken } from '@/plugins/axios'
import { TransporterError } from '@/lib/Errors'
import TransporterVehicle from '@/models/TransporterVehicle'

export default {
  async getTransporterVehicle () {
    requireAuthToken()
    const response = await $http.get('/transportervehicle')

    if (response.data.resFlag) {
      const userJson = response.data.data.map(transporterVehicle => {
        return new TransporterVehicle(transporterVehicle)
      })

      return userJson
    } else {
      throw new TransporterError('error')
    }
  },
  async getTransporterVehicleById (id) {
    requireAuthToken()
    const response = await $http.get(`/transportervehicle/${id}`)

    if (response.data.resFlag) {
      const userJson = response.data.data
      return userJson
    } else {
      throw new TransporterError('Invalid id')
    }
  },
  async createTransporterVehicle (body) {
    const response = await $http.post('/transportervehicle', body)

    if (response.data.resFlag) {
      const userJson = response.data.data
      return userJson
    } else {
      throw new TransporterError('Error')
    }
  },
  async updateTransporterVehicle (body) {
    const response = await $http.put(`/transportervehicle/${body.id}`, body)

    if (response.data.resFlag) {
      const userJson = response.data.data
      return userJson
    } else {
      throw new TransporterError('Error')
    }
  },
}

import $http from '@/plugins/axios'
import { ConsumerError } from '@/lib/Errors'

export default {
  async createConsumerUser (body) {
    const response = await $http.post('/consumeruser', body)
    if (response.data.resFlag) {
      return response.data
    } else {
      throw new ConsumerError('Error')
    }
  },
  async deleteConsumerUser (body) {
    const response = await $http.delete('/consumeruser/deleteuseridconsumerid', { data: body })

    if (response.data.resFlag) {
      return response.data
    } else {
      throw new ConsumerError('Error')
    }
  },
}

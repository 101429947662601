import $http, { requireAuthToken } from '@/plugins/axios'
import { CoiBranchesError } from '@/lib/Errors'
import CoiBranches from '@/models/CoiBranches'
// coi = customer offered items
export default {
  async getCoiBranches () {
    requireAuthToken()
    const response = await $http.get('/coibranchespivot')
    if (response.data.resFlag) {
      const userJson = response.data.data.map(branch => {
        return new CoiBranches(branch)
      })
      return userJson
    } else {
      throw new CoiBranchesError('Unable to fetch the COI branches')
    }
  },
  async getCoiBranchesById (id) {
    requireAuthToken()
    const response = await $http.get(`/coibranchespivot/${id}`)
    if (response.data.resFlag) {
      const userJson = response.data.data
      return userJson
    } else {
      throw new CoiBranchesError('Invalid id')
    }
  },
  async createCoiBranches (body) {
    const response = await $http.post('/coibranchespivot', body)

    if (response.data.resFlag) {
      const userJson = response.data
      return userJson
    } else {
      throw new CoiBranchesError('Error creating the COI branches')
    }
  },

  async updateCoiBranches (body) {
    const response = await $http.put(`/coibranchespivot/${body.id}`, body)

    if (response.data.resFlag) {
      const userJson = response.data
      return userJson
    } else {
      throw new CoiBranchesError('Error updating the COI branches')
    }
  },

  async deleteCoiBranches (id) {
    const response = await $http.delete(`/coibranchespivot/${id}`)

    if (response.data.resFlag) {
      return response.data
    } else {
      throw new CoiBranchesError('Error deleting the COI branches')
    }
  },
  async deleteCoiByItemAndBranch (body) {
    const response = await $http.delete('/coibranchespivot/deletebyitemidbranchofficeid', { data: body })

    if (response.data.resFlag) {
      return response.data
    } else {
      throw new CoiBranchesError('Error deleting the COI branches')
    }
  },
}

module.exports = class Contract {
  constructor (object) {
    this.CustomerId = object.customerId
    this.ConsumerId = object.consumerId
    this.CustomerData = object.customerData
    this.ConsumerData = object.consumerData
    this.ContractFromDate = object.contractFromDate.split('T')[0]
    this.ContractUntilDate = object.contractUntilDate.split('T')[0]
    this.Status = object.Status
    this.Id = object.id
  }
}

import Repository from '@/repositories/RepositoryFactory'

const CustomerRepository = Repository.get('customer')

export default {
  namespaced: true,
  state: {
    customers: null,
    selectedCustomer: null,
  },
  mutations: {
    GET_CUSTOMER (state, payload) {
      state.customers = payload
    },
    GET_CUSTOMER_BY_ID (state, payload) {
      state.selectedCustomer = payload
    },
    // CREATE_CUSTOMER (state, payload) {
    // }
  },
  actions: {
    async getCustomer ({ commit }, data) {
      commit('GET_CUSTOMER', await CustomerRepository.getCustomer())
    },
    async getCustomerById ({ commit }, id) {
      commit('GET_CUSTOMER_BY_ID', await CustomerRepository.getCustomerById(id))
    },
    async createCustomer ({ commit }, data) {
      const res = await CustomerRepository.createCustomer(data)
      return res
    },
    async updateCustomer ({ commit }, body) {
      const res = await CustomerRepository.updateCustomer(body)
      return res
    },
  },
  getters: {

  },
}

import $http, { requireAuthToken } from '@/plugins/axios'
import { RegionalOfficeUserError } from '@/lib/Errors'
import BranchOfficeUser from '@/models/BranchOfficeUser'

export default {
  async getBranchUser () {
    requireAuthToken()
    const response = await $http.get('/branchofficeuser')

    if (response.data.resFlag) {
      const userJson = response.data.data.map(branchofficeuser => {
        return new BranchOfficeUser(branchofficeuser)
      })
      return userJson
    } else {
      throw new RegionalOfficeUserError('Unable to fetch staff')
    }
  },
  async assignuseridbranchofficeid (body) {
    requireAuthToken()
    const response = await $http.post('/branchofficeuser/assignuseridbranchofficeid', body)

    if (response.data.resFlag) {
      const userJson = response.data.data
      return userJson
    } else {
      throw new RegionalOfficeUserError('Unable to fetch staff')
    }
  },

}

import $http, { requireAuthToken } from '@/plugins/axios'
import { BranchOfficeError } from '@/lib/Errors'
import BranchOffice from '@/models/BranchOffice'

export default {
  async getBranchOffice () {
    requireAuthToken()
    const response = await $http.get('/branchoffice')
    if (response.data.resFlag) {
      const userJson = response.data.data.map(branch => {
        return new BranchOffice(branch)
      })
      return userJson
    } else {
      throw new BranchOfficeError('Unable to fetch branch offices')
    }
  },
  async getBranchOfficeById (id) {
    requireAuthToken()
    const response = await $http.get(`/branchoffice/${id}`)
    if (response.data.resFlag) {
      const userJson = new BranchOffice(response.data.data)
      return userJson
    } else {
      throw new BranchOfficeError('Invalid id')
    }
  },
  async createBranchOffice (body) {
    const response = await $http.post('/branchoffice', body)

    if (response.data.resFlag) {
      const userJson = response.data
      return userJson
    } else {
      throw new BranchOfficeError('Error creating branch office')
    }
  },

  async updateBranchOffice (body) {
    const response = await $http.put(`/branchoffice/${body.id}`, body)

    if (response.data.resFlag) {
      const userJson = response.data
      return userJson
    } else {
      throw new BranchOfficeError('Error updating branch office')
    }
  },

  async deleteBranchOffice (id) {
    const response = await $http.delete(`/branchoffice/${id}`)

    if (response.data.resFlag) {
      return response.data
    } else {
      throw new BranchOfficeError('Error deleting the branch office')
    }
  },
}

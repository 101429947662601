import $http, { requireAuthToken } from '@/plugins/axios'
import { TransporterError } from '@/lib/Errors'
import TransporterSecurities from '@/models/TransporterSecurities'

export default {
  async getTransporterSecurities () {
    requireAuthToken()
    const response = await $http.get('/transportersecurities')

    if (response.data.resFlag) {
      const userJson = response.data.data.map(transporterSecurities => {
        return new TransporterSecurities(transporterSecurities)
      })

      return userJson
    } else {
      throw new TransporterError('error')
    }
  },
  async getTransporterSecuritiesById (id) {
    requireAuthToken()
    const response = await $http.get(`/transportersecurities/${id}`)

    if (response.data.resFlag) {
      const userJson = response.data.data
      return userJson
    } else {
      throw new TransporterError('Invalid id')
    }
  },
  async createTransporterSecurities (body) {
    const response = await $http.post('/transportersecurities', body)

    if (response.data.resFlag) {
      const userJson = response.data.data
      return userJson
    } else {
      throw new TransporterError('Error')
    }
  },
  async updateTransporterSecurities (body) {
    const response = await $http.put(`/transportersecurities/${body.id}`, body)

    if (response.data.resFlag) {
      const userJson = response.data.data
      return userJson
    } else {
      throw new TransporterError('Error')
    }
  },
}

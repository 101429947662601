import Repository from '@/repositories/RepositoryFactory'

const OrderRepository = Repository.get('order')

export default {
  namespaced: true,
  state: {
    orders: null,
    order: null,
  },
  mutations: {
    GET_ORDER (state, payload) {
      state.orders = payload
    },
    LOAD_ORDER_BY_ID (state, payload) {
      state.order = payload
    },
  },
  actions: {
    async getOrders ({ commit }, data) {
      commit('GET_ORDER', await OrderRepository.getOrders())
    },
    async getOrderById ({ commit }, id) {
      commit('LOAD_ORDER_BY_ID', await OrderRepository.getOrderById(id))
    },
    async setOrderStatusById ({ commit }, body) {
      const res = await OrderRepository.setOrderStatusById(body)
      return res
    },
  },
  getters: {

  },

}

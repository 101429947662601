import $http, { requireAuthToken } from '@/plugins/axios'
import { OrderError } from '@/lib/Errors'
import Order from '@/models/Order'
import OrderDetails from '@/models/OrderDetails'

export default {
  async getOrders () {
    requireAuthToken()
    const response = await $http.get('/order')

    if (response.data.resFlag) {
      const userJson = response.data.data.map(order => {
        return new Order(order)
      })
      return userJson
    } else {
      throw new OrderError('Unable to fetch orders')
    }
  },
  async getOrderById (id) {
    requireAuthToken()
    const response = await $http.get(`/order/${id}`)

    if (response.data.resFlag) {
      const orderJSON = new OrderDetails(response.data.data)
      return orderJSON
    } else {
      throw new OrderError('Unable to fetch order')
    }
  },
  async setOrderStatusById (body) {
    const response = await $http.put('/order/setstatus', {}, { headers: { orderId: body.OrderId, statusValueToSet: body.statusValueToSet } })

    if (response.data.resFlag) {
      return response
    } else {
      throw new OrderError('Unable to fetch order')
    }
  },
}

import Repository from '@/repositories/RepositoryFactory'
import { setAuthToken } from '@/plugins/axios'

const AuthenticationRepository = Repository.get('authentication')

export default {
  namespaced: true,
  state: {
    userObject: null,
    sessionToken: null,
    roleId: null,
    userData: null,
    branchOfficeData: null,
  },
  mutations: {
    DO_LOGIN (state, payload) {
      state.userObject = payload
      state.sessionToken = payload.Session.sessionGuid
      state.userData = payload.User
      state.branchOfficeData = payload.BranchOffice
      localStorage.sessionToken = payload.Session.sessionGuid
      localStorage.userData = payload.User
      setAuthToken(payload.Session.sessionGuid)
      state.roleId = payload.User.roleId
    },
    LOGOUT (state) {
      localStorage.clear()
      state.sessionToken = null
      setAuthToken(null)
    },
    initialize (state) {
      // TODO: In the future, we can load all stored state from
      //       localStorage with https://www.mikestreety.co.uk/blog/vue-js-using-localstorage-with-the-vuex-store
      state.sessionToken = localStorage.sessionToken
      setAuthToken(state.sessionToken)
    },
  },
  actions: {
    async doLogin ({ commit }, data) {
      commit('DO_LOGIN', await AuthenticationRepository.login(data.email, data.password))
    },
    async doLoginWithToken ({ commit, state }) {
      commit('DO_LOGIN', await AuthenticationRepository.getUserData())
    },
    async doLogout ({ commit, state }) {
      commit('LOGOUT', await AuthenticationRepository.logout())
    },
  },
  getters: {
    hasStoredSession (state) {
      return (!!state.sessionToken)
    },
    getRole (state) {
      return state.userObject.User.roleData.roleName
    },
    getName (state) {
      return state.userObject.User.firstName
    },
  },
}

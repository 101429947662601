import Repository from '@/repositories/RepositoryFactory'

const ConsumerUserRepository = Repository.get('consumeruser')

export default {
  namespaced: true,
  state: {
  },
  mutations: {
  },
  actions: {
    async createConsumerUser ({ commit }, data) {
      const res = await ConsumerUserRepository.createConsumerUser(data)
      return res
    },
    async deleteConsumerUser ({ commit }, data) {
      const res = await ConsumerUserRepository.deleteConsumerUser(data)
      return res
    },
  },
  getters: {

  },

}

import Repository from '@/repositories/RepositoryFactory'

const DeliveryRepository = Repository.get('deliveries')

export default {
  namespaced: true,
  state: {
    deliveries: null,
    detailedDelivery: null,
  },
  mutations: {
    GET_DELIVERY (state, payload) {
      state.deliveries = payload
    },
    GET_DELIVERY_BY_ID (state, payload) {
      state.detailedDelivery = payload
    },
  },
  actions: {
    async getDelivery ({ commit }, data) {
      commit('GET_DELIVERY', await DeliveryRepository.getDelivery())
    },
    async getDeliveryById ({ commit }, id) {
      commit('GET_DELIVERY_BY_ID', await DeliveryRepository.getDeliveryById(id))
    },
    async createDelivery ({ commit }, data) {
      const res = await DeliveryRepository.createDelivery(data)
      return res
    },
    async updateDelivery ({ commit }, body) {
      const res = await DeliveryRepository.updateDelivery(body)
      return res
    },
  },
  getters: {

  },

}

import $http, { requireAuthToken } from '@/plugins/axios'
import { DeliveryError } from '@/lib/Errors'
import Delivery from '@/models/Deliveries'

export default {
  async getDelivery () {
    const response = await $http.get('/delivery')
    if (response.data.resFlag) {
      const userJson = response.data.data.map(delivery => {
        return new Delivery(delivery)
      })
      return userJson
    }

    throw new DeliveryError('Unable to fetch delivery')
  },
  async getDeliveryById (id) {
    requireAuthToken()
    const response = await $http.get(`/delivery/${id}`)

    if (response.data.resFlag) {
      const userJson = response.data.data
      return userJson
    } else {
      throw new DeliveryError('Invalid id')
    }
  },
  async createDelivery (body) {
    requireAuthToken()
    const response = await $http.post('/delivery', body)
    if (response.data.resFlag) {
      const userJson = response.data
      return userJson
    } else {
      throw new DeliveryError('Unable to create delivery')
    }
  },
  async updateDelivery (body) {
    const response = await $http.put(`/delivery/${body.id}`, body)

    if (response.data.resFlag) {
      const userJson = response.data
      return userJson
    } else {
      throw new DeliveryError('Error')
    }
  },
}

import Repository from '@/repositories/RepositoryFactory'

const DealerUserRepository = Repository.get('dealeruser')

export default {
  namespaced: true,
  state: {
  },
  mutations: {
  },
  actions: {
    async createDealerUser ({ commit }, data) {
      const res = await DealerUserRepository.createDealerUser(data)
      return res
    },
    async deleteDealerUser ({ commit }, data) {
      const res = await DealerUserRepository.deleteDealerUser(data)
      return res
    },
  },
  getters: {

  },

}

import Repository from '@/repositories/RepositoryFactory'

const VerifierRepository = Repository.get('verifier')

export default {
  namespaced: true,
  entytiStatuses: {
    offerStatuses: null,
    customerStatuses: null,
    orderStatuses: null,
    userStatuses: null,
    regionStatuses: null,
    branchStatuses: null,
    deliveryOrderStatuses: null,
    deliveryStatuses: null,
  },
  state: {
    verifiers: null,
    selectedVerifierById: null,
    selectedVerifierByDomainAndValue: null,
    userStatuses: null,
  },
  mutations: {
    GET_VERIFIER (entytiStatuses, payload) {
      entytiStatuses.verifiers = payload
    },
    GET_VERIFIER_BY_ID (entytiStatuses, payload) {
      entytiStatuses.selectedVerifierById = payload
    },
    GET_VERIFIER_BY_DOMAIN_AND_VALUE (entytiStatuses, payload) {
      entytiStatuses.selectedVerifierByDomainAndValue = payload
    },
    GET_OFFER_STATUS (entytiStatuses, payload) {
      entytiStatuses.offerStatuses = payload
    },
    GET_CUSTOMER_STATUS (entytiStatuses, payload) {
      entytiStatuses.customerStatuses = payload
    },
    GET_ORDER_STATUS (entytiStatuses, payload) {
      entytiStatuses.orderStatuses = payload
    },
    GET_USER_STATUS (entytiStatuses, payload) {
      entytiStatuses.userStatuses = payload
    },
    GET_REGION_STATUS (entytiStatuses, payload) {
      entytiStatuses.regionStatuses = payload
    },
    GET_BRANCH_STATUS (entytiStatuses, payload) {
      entytiStatuses.branchStatuses = payload
    },
    GET_DELIVERY_STATUS (entytiStatuses, payload) {
      entytiStatuses.deliveryStatuses = payload
    },
    GET_DELIVERY_ORDER_STATUS (entytiStatuses, payload) {
      entytiStatuses.deliveryOrderStatuses = payload
    },
  },
  actions: {
    async getVerifier ({ commit }, data) {
      commit('GET_VERIFIER', await VerifierRepository.getVerifier())
    },
    async getVerifiersForDomain ({ commit }, domain) {
      commit('GET_VERIFIER', await VerifierRepository.getVerifiersForDomain(domain))
    },
    async getVerifiersForDomainAndValue ({ commit }, body) {
      commit('GET_VERIFIER_BY_DOMAIN_AND_VALUE', await VerifierRepository.getVerifiersForDomainAndValue(body))
    },
    async getVerifiersForOffer ({ commit }) {
      commit('GET_OFFER_STATUS', await VerifierRepository.getVerifiersForDomain('OFFER_STATUS'))
    },
    async getVerifiersForCustomer ({ commit }) {
      commit('GET_CUSTOMER_STATUS', await VerifierRepository.getVerifiersForDomain('CUSTOMER_STATUS'))
    },
    async getVerifiersForOrder ({ commit }) {
      commit('GET_ORDER_STATUS', await VerifierRepository.getVerifiersForDomain('ORDER_STATUS'))
    },
    async getVerifiersForUser ({ commit }) {
      commit('GET_USER_STATUS', await VerifierRepository.getVerifiersForDomain('USER_STATUS'))
    },
    async getVerifiersForRegion ({ commit }) {
      commit('GET_REGION_STATUS', await VerifierRepository.getVerifiersForDomain('REGION_STATUS'))
    },
    async getVerifiersForBranch ({ commit }) {
      commit('GET_BRANCH_STATUS', await VerifierRepository.getVerifiersForDomain('BRANCH_STATUS'))
    },
    async getVerifiersForDelivery ({ commit }) {
      commit('GET_DELIVERY_STATUS', await VerifierRepository.getVerifiersForDomain('DELIVERY_STATUS'))
    },
    async getVerifiersForDeliveryOrder ({ commit }) {
      commit('GET_DELIVERY_ORDER_STATUS', await VerifierRepository.getVerifiersForDomain('DELIVERY_ORDER_STATUS'))
    },
    // async getVerifiersForDomainAndValue ({ commit }, body) {
    //   commit('GET_VERIFIER_BY_DOMAIN_AND_VALUE', await VerifierRepository.getVerifiersForDomainAndValue(body))
    // },
    // async getVerifierById ({ commit }, id) {
    //   commit('GET_VERIFIER_BY_ID', await VerifierRepository.getVerifierById(id))
    // },
    async createVerifier ({ commit }, data) {
      const res = await VerifierRepository.createVerifier(data)
      return res
    },
    async updateVerifier ({ commit }, body) {
      const res = await VerifierRepository.updateVerifier(body)
      return res
    },
  },
  getters: {
    getObjectVerifierById: (entytiStatuses) => (array, id) => {
      var result = (array.filter(e => e.LowValue === id)[0])
      var error = result === undefined || result === null
      if (error) {
        return `this verifier with this , ${id}, is null, undefined or not exist`
      }
      return result
    },
    getVerifierCustomerId: (entytiStatuses) => (array, id) => {
      var result = (array.filter(e => e.LowValue === id)[0])
      var error = result === undefined || result === null
      if (error) {
        return `this verifier with this , ${id}, is null, undefined or not exist`
      }
      return result.CustomerId
    },
    getVerifierId: (entytiStatuses) => (array, id) => {
      var result = (array.filter(e => e.LowValue === id)[0])
      var error = result === undefined || result === null
      if (error) {
        return `this verifier with this , ${id}, is null, undefined or not exist`
      }
      return result.Id
    },
    getVerifierLongDescription: (entytiStatuses) => (array, id) => {
      var result = (array.filter(e => e.LowValue === id)[0])
      var error = result === undefined || result === null
      if (error) {
        return `this verifier with this , ${id}, is null, undefined or not exist`
      }
      return result.LongDescription
    },
    getVerifierLowValue: (entytiStatuses) => (array, id) => {
      var result = (array.filter(e => e.LowValue === id)[0])
      var error = result === undefined || result === null
      if (error) {
        return `this verifier with this , ${id}, is null, undefined or not exist`
      }
      return result.LowValue
    },
    getVerifierRvDomain: (entytiStatuses) => (array, id) => {
      var result = (array.filter(e => e.LowValue === id)[0])
      var error = result === undefined || result === null
      if (error) {
        return `this verifier with this , ${id}, is null, undefined or not exist`
      }
      return result.RvDomain
    },
    getVerifierShortDescription: (entytiStatuses) => (array, id) => {
      var result = (array.filter(e => e.LowValue === id)[0])
      var error = result === undefined || result === null
      if (error) {
        return `this verifier with this , ${id}, is null, undefined or not exist`
      }
      return result.ShortDescription
    },
  },

}

import Repository from '@/repositories/RepositoryFactory'

const ConsumerRepository = Repository.get('consumer')

export default {
  namespaced: true,
  state: {
    consumers: null,
    selectedConsumer: null,
  },
  mutations: {
    GET_CONSUMER (state, payload) {
      state.consumers = payload
    },
    GET_CONSUMER_BY_ID (state, payload) {
      state.selectedConsumer = payload
    },
  },
  actions: {
    async getConsumer ({ commit }, data) {
      commit('GET_CONSUMER', await ConsumerRepository.getConsumer())
    },
    async createConsumer ({ commit }, data) {
      const res = await ConsumerRepository.createConsumer(data)
      return res
    },
    async getConsumerById ({ commit }, id) {
      commit('GET_CONSUMER_BY_ID', await ConsumerRepository.getConsumerById(id))
    },
    async updateConsumer ({ commit }, body) {
      const res = await ConsumerRepository.updateConsumer(body)
      return res
    },
  },
  getters: {

  },

}

module.exports = class Verifier {
  constructor (object) {
    this.Id = object.id
    this.CustomerId = object.customerId
    this.RvDomain = object.rvDomain
    this.LowValue = Number(object.lowValue)
    this.ShortDescription = object.shortDescription
    this.LongDescription = object.longDescription
  }
}

import $http, { requireAuthToken } from '@/plugins/axios'
import { CustomerOfferedItemsError } from '@/lib/Errors'
import CustomerOfferedItems from '@/models/CustomerOfferedItems'

export default {
  async getCustomerOfferedItems () {
    requireAuthToken()
    const response = await $http.get('/customeroffereditems')
    if (response.data.resFlag) {
      const userJson = response.data.data.map(item => {
        return new CustomerOfferedItems(item)
      })

      return userJson
    } else {
      throw new CustomerOfferedItems('Error')
    }
  },
  async getCustomerOfferedItemsById (id) {
    requireAuthToken()
    const response = await $http.get(`/customeroffereditems/${id}`)

    if (response.data.resFlag) {
      const userJson = new CustomerOfferedItems(response.data.data)
      return userJson
    } else {
      throw new CustomerOfferedItemsError('Invalid id')
    }
  },
  async createCustomerOfferedItems (body) {
    const response = await $http.post('/customeroffereditems', body)
    if (response.data.resFlag) {
      const userJson = new CustomerOfferedItems(response.data.data)
      userJson.resFlag = response.data.resFlag
      return userJson
    } else {
      throw new CustomerOfferedItemsError('Error')
    }
  },
  async updateCustomerOfferedItems (body) {
    const response = await $http.put(`/customeroffereditems/${body.id}`, body)
    if (response.data.resFlag) {
      const userJson = response.data
      return userJson
    } else {
      throw new CustomerOfferedItemsError('Error')
    }
  },
  async deleteCustomerOfferedItems (id) {
    const response = await $http.delete(`/customeroffereditems/${id}`)

    if (response.data.resFlag) {
      const userJson = response.data
      return userJson
    } else {
      throw new CustomerOfferedItemsError('Error')
    }
  },
  async getOfferPhoto (id) {
    const response = await $http.get('/customeroffereditems/getcoiphoto', { headers: { ItemId: id } })
    if (response.data.resFlag) {
      const userJson = response.data
      return userJson
    } else {
      throw new CustomerOfferedItemsError('Error')
    }
  },
  async uploadOfferImage (body) {
    const response = await $http.post('/customeroffereditems/uploadcoiphoto', body.formData,
    {
      headers: { 'Content-Type': 'multipart/form-data', ItemId: body.id },
    })
    if (response.data.resFlag) {
      const userJson = response.data
      return userJson
    } else {
      throw new CustomerOfferedItemsError('Could not upload image!')
    }
  },
}

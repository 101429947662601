import $http, { requireAuthToken } from '@/plugins/axios'
import { ContractError } from '@/lib/Errors'
import Contract from '@/models/Contract'

export default {
  async getContract () {
    requireAuthToken()
    const response = await $http.get('/contract')
    if (response.data.resFlag) {
      const obj = response.data.data.map(contract => {
        return new Contract(contract)
      })
      return obj
    }

    throw new ContractError('Unable to fetch contracts')
  },
  async getContractById (id) {
    requireAuthToken()
    const response = await $http.get(`/contract/${id}`)

    if (response.data.resFlag) {
      // TODO: Create response returns using models
      const userJson = response.data.data
      return userJson
    } else {
      throw new ContractError('Invalid id')
    }
  },
  async createContract (body) {
    const response = await $http.post('/contract', body)

    if (response.data.resFlag) {
      const userJson = response.data.data.map(contract => {
        return new Contract(contract)
      })
      return userJson
    } else {
      throw new ContractError('Error')
    }
  },
}

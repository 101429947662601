module.exports = class Role {
  constructor (object) {
    this.Id = object.id
    this.Description = object.roleDescription
    this.Name = object.roleName
    this.DisplayName = object.roleNameForDisplay
    this.Data = {
      id: object.id,
      name: object.roleName,
    }
  }
}

import Repository from '@/repositories/RepositoryFactory'

const RoleRepository = Repository.get('role')

export default {
  namespaced: true,
  state: {
    roles: null,
  },
  mutations: {
    GET_ROLE (state, payload) {
      state.roles = payload
    },
  },
  actions: {
    async getRole ({ commit }, data) {
      commit('GET_ROLE', await RoleRepository.getRole())
    },
  },
  getters: {

  },

}

import $http from '@/plugins/axios'
import { VerifierError } from '@/lib/Errors'
import Verifier from '@/models/Verifier'

export default {
  async getVerifier () {
    const response = await $http.get('/verifier')
    if (response.data.resFlag) {
      const verifierJson = response.data.data.map(verifier => {
        return new Verifier(verifier)
      })
      return verifierJson
    }

    throw new VerifierError('Unable to fetch verifier')
  },
  async getVerifierById (id) {
    const response = await $http.get(`/verifier/${id}`)

    if (response.data.resFlag) {
      const verifierJson = new Verifier(response.data.data)
      return verifierJson
    } else {
      throw new VerifierError('Invalid id')
    }
  },
  async getVerifiersForDomain (domain) {
    const response = await $http.get('/verifier/getverifiersfordomain', { params: { domain: domain } })
    if (response.data.resFlag) {
      const verifierJson = response.data.data.map(verifier => {
        return new Verifier(verifier)
      })
      return verifierJson
    }

    throw new VerifierError('Unable to fetch verifier for the selected verifier')
  },
  async getVerifiersForDomainAndValue (body) {
    const response = await $http.get(`/verifier/getverifierfordomainandlowvalue?domain=${body.domain}&lowvalue=${body.lowValue}`)

    if (response.data.resFlag) {
      const verifierJson = new Verifier(response.data.data)
      return verifierJson
    } else {
      throw new VerifierError('Invalid id')
    }
  },
  async createVerifier (body) {
    const response = await $http.post('/verifier', body)

    if (response.data.resFlag) {
      // TODO: Create response returns using models
      return response.data.data
    } else {
      throw new VerifierError('Error')
    }
  },
  async updateVerifier (body) {
    const response = await $http.put(`/verifier/${body.id}`, body)

    if (response.data.resFlag) {
      const userJson = response.data.data
      return userJson
    } else {
      throw new VerifierError('Error')
    }
  },
}

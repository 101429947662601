import Repository from '@/repositories/RepositoryFactory'

const TransporterSecurities = Repository.get('transporterSecurities')

export default {
  namespaced: true,
  state: {
    transporterSecurities: null,
    selectedTransporterSecurities: null,
  },
  mutations: {
    GET_TRANSPORTER_SECURITIES (state, payload) {
      state.transporterSecurities = payload
    },
    GET_TRANSPORTER_SECURITIES_BY_ID (state, payload) {
      state.selectedTransporterSecurities = payload
    },
  },
  actions: {
    async getTransporterSecurities ({ commit }, data) {
      commit('GET_TRANSPORTER_SECURITIES', await TransporterSecurities.getTransporterSecurities())
    },
    async getTransporterSecuritiesById ({ commit }, id) {
      commit('GET_TRANSPORTER_SECURITIES_BY_ID', await TransporterSecurities.getTransporterSecuritiesById(id))
    },
    async createTransporterSecurities ({ commit }, data) {
      const res = await TransporterSecurities.createTransporterSecurities(data)
      return res
    },
    async updateTransporterSecurities ({ commit }, body) {
      const res = await TransporterSecurities.updateTransporterSecurities(body)
      return res
    },
  },
  getters: {

  },

}

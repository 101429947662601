import AuthenticationRepository from './v1/AuthenticationRepository'
import UserRepository from './v1/UserRepository'
import SecurityCompanyRepository from './v1/SecurityCompanyRepository'
import CustomerRepository from './v1/CustomerRepository'
import AddressRepository from './v1/AddressRepository'
import RoleRepository from './v1/RoleRepository'
import CustomerOfferedItemsRepository from './v1/CustomerOfferedItemsRepository'
import ConsumerRepository from './v1/ConsumerRepository'
import ContractRepository from './v1/ContractRepository'
import DealerRepository from './v1/DealerRepository'
import VerifierRepository from './v1/VerifierRepository'
import ConsumerUserRepository from './v1/ConsumerUserRepository'
import DealerUserRepository from './v1/DealerUserRepository'
import WarehouseRepository from './v1/WarehouseRepository'
import TransporterMetaDataRepository from './v1/TransporterMetaDataRepository'
import WarehouseUserRepository from './v1/WarehouseUserRepository'
import TransporterVehicleRepository from './v1/TransporterVehicleRepository'
import TransporterSecuritiesRepository from './v1/TransporterSecuritiesRepository'
import RegionalOfficeRepository from './v1/RegionalOfficeRepository'
import DeliveriesRepository from './v1/DeliveriesRepository'
import OrderRepository from './v1/OrderRepository'
import RegionalOfficeUserRepository from './v1/RegionalOfficeUserRepository'
import BranchOfficeRepository from './v1/BranchOfficeRepository'
import BranchOfficeUserRepository from './v1/BranchOfficeUserRepository'
import CoiBranchesRepository from './v1/CoiBranchesRepository'
import DeliveryOrderRepository from './v1/DeliveryOrderRepository'

const repositories = {
  authentication: AuthenticationRepository,
  user: UserRepository,
  securitycompany: SecurityCompanyRepository,
  customer: CustomerRepository,
  address: AddressRepository,
  role: RoleRepository,
  customerOfferedItems: CustomerOfferedItemsRepository,
  consumer: ConsumerRepository,
  contract: ContractRepository,
  dealer: DealerRepository,
  verifier: VerifierRepository,
  consumeruser: ConsumerUserRepository,
  dealeruser: DealerUserRepository,
  warehouse: WarehouseRepository,
  transporterMetaData: TransporterMetaDataRepository,
  warehouseuser: WarehouseUserRepository,
  transporterVehicle: TransporterVehicleRepository,
  transporterSecurities: TransporterSecuritiesRepository,
  regionaloffice: RegionalOfficeRepository,
  deliveries: DeliveriesRepository,
  order: OrderRepository,
  regionalOfficeUser: RegionalOfficeUserRepository,
  branchOffice: BranchOfficeRepository,
  branchOfficeUser: BranchOfficeUserRepository,
  coiBranchesPivot: CoiBranchesRepository,
  deliveryOrder: DeliveryOrderRepository,
}

export default {
  get: (name) => {
    if (repositories[name]) {
      return repositories[name]
    } else {
      throw new Error('Invalid Repository Type')
    }
  },
}

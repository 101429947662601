import Repository from '@/repositories/RepositoryFactory'

const RegionalOfficeUserRepository = Repository.get('regionalOfficeUser')

export default {
  namespaced: true,
  state: {
    regionalofficeuser: null,
  },
  mutations: {
    GET_REGIONAL_OFFICE_USER (state, payload) {
      state.regionalofficeuser = payload
    },
  },
  actions: {
    async getRegionalUser ({ commit }, data) {
      commit('GET_REGIONAL_OFFICE_USER', await RegionalOfficeUserRepository.getRegionalUser())
    },
    async assignuseridregionalofficeid ({ commit }, body) {
      const res = await RegionalOfficeUserRepository.assignuseridregionalofficeid(body)
      return res
    },
  },
  getters: {

  },

}

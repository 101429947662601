import Error from '@/lib/extendable-error'

export class APIServerError extends Error {
  constructor (message, response) {
    super(message)
    this.response = response
  }
}

export class AuthenticationError extends Error { }
export class UserError extends Error { }
export class CustomerError extends Error { }
export class AddressError extends Error { }
export class CreateCustomerError extends Error { }
export class SecurityCompanyError extends Error { }
export class RoleError extends Error { }
export class CustomerOfferedItemsError extends Error { }
export class ConsumerError extends Error { }
export class ContractError extends Error { }
export class VerifierError extends Error { }
export class DealerError extends Error { }
export class WarehouseError extends Error { }
export class TransporterError extends Error { }
export class RegionalOfficeError extends Error { }
export class DeliveryError extends Error { }
export class OrderError extends Error { }
export class RegionalOfficeUserError extends Error { }
export class BranchOfficeError extends Error { }
export class CoiBranchesError extends Error { }
export class DeliveryOrderError extends Error { }

import Repository from '@/repositories/RepositoryFactory'

const WarehouseUserRepository = Repository.get('warehouseuser')

export default {
  namespaced: true,
  state: {
  },
  mutations: {
  },
  actions: {
    async createWarehouseUser ({ commit }, data) {
      const res = await WarehouseUserRepository.createWarehouseUser(data)
      return res
    },
    async deleteWarehouseUser ({ commit }, data) {
      const res = await WarehouseUserRepository.deleteWarehouseUser(data)
      return res
    },
  },
  getters: {

  },

}

const Address = require('./Address')
const Customers = require('./Customers')
const Role = require('./Role')
module.exports = class User {
  constructor (object) {
    this.Id = object.id !== null ? object.id : ''
    this.Address = typeof object.addressData !== 'undefined' ? new Address(object.addressData) : ''
    this.Customer = object.customerData !== null ? new Customers(object.customerData, 1) : ''
    this.CustomerId = object.customerId !== null ? object.customerId : ''
    this.Role = object.roleData !== null ? new Role(object.roleData) : ''
    this.RoleName = object.roleData !== null ? object.roleData.roleName : ''
    this.Email = object.email
    this.Name = object.firstName
    this.Surname = object.lastName
    this.Phone = object.phone
    this.Status = object.status
    this.Username = object.username
    this.FullName = object.firstName + ' ' + object.lastName
    this.FormattedAddress = getAddress(object.addressData)
    this.BranchOfficeId = object.branchOfficeId !== null ? object.branchOfficeId : ''
    this.BranchOfficeData = object.branchOfficeData !== null ? object.branchOfficeData : {}
    this.RegionalOfficeId = object.regionalOfficeId !== null ? object.regionalOfficeId : ''
    this.RegionalOfficeData = object.regionalOfficeData !== null ? object.regionalOfficeData : {}
  }
}
const getAddress = (addressData) => {
  return `${addressData.zipCode}, ${addressData.addressLine}, ${addressData.city}, ${addressData.country}`
}

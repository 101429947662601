module.exports = class Order {
  constructor (object) {
    this.Id = object.id
    this.OrderCode = object.orderCode
    this.OrderTimestamp = object.orderTimestamp
    this.OrderDate = object.orderDate
    this.CustomerId = object.customerId
    this.ContractId = object.contractId
    this.ConsumerId = object.consumerId
    this.ConsumerUserId = object.consumerUserId
    this.WarehouseId = object.warehouseId
    this.DealerId = object.dealerId
    this.TotalOrderPrice = object.totalOrderPrice
    this.Status = object.status
    this.ConsumerData = object.consumerData
    this.StatusId = object.status
  }
}

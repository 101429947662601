module.exports = class OrderDetails {
    constructor (object) {
        this.ConsumerData = object.consumerData
        this.ConsumerUserData = object.consumerUserData
        this.ContractData = object.contractData
        this.CustomerData = object.customerData
        this.ContractId = object.contractId
        this.ConsumerId = object.consumerId
        this.ConsumerUserId = object.consumerUserId
        this.CustomerId = object.customerId
        this.DealerData = object.dealerData
        this.DealerId = object.dealerId
        this.Id = object.id
        this.OrderCode = object.orderCode
        this.OrderDate = object.orderDate
        this.OrderDetailsList = object.orderDetailsList
        this.OrderTimestamp = object.orderTimestamp
        this.Status = object.status
        this.TotalOrderPrice = object.totalOrderPrice
        this.WarehouseData = object.warehouseData
        this.WarehouseId = object.warehouseId
        this.DeliveryData = object.deliveryData
    }
}

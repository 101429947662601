module.exports = class Address {
  constructor (object) {
    this.Id = object.id
    this.Name = object.name
    this.AddressId = object.addressId
    this.ContactPerson = object.contactPerson
    this.ContactPhone = object.contactPhone
    this.ContactEmail = object.contactEmail
    this.CustomerId = object.customerId
  }
}

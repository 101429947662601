import { AuthenticationError } from '@/lib/Errors'
import $http, { requireAuthToken } from '@/plugins/axios'
import Authentication from '@/models/Authentication'

export default {
  async checkAuth () {
    requireAuthToken()
    const response = await $http.post('/auth/signinwithtoken')
    if (response.data.resFlag) {
      return true
    }

    throw new AuthenticationError('Invalid username or password')
  },
  async login (email, password) {
    const body = {
      username: email,
      password: password,
      sessionType: 'WEB',
      // TODO: create dynamically
      sessionGuid: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
      ip: '127.0.0.1',
    }

    const response = await $http.post('/auth/signin', body)

    if (response.data.resFlag) {
      const userJson = new Authentication(response.data.data)
      return userJson
    } else {
      throw new AuthenticationError('Invalid username or password')
    }
  },
  async logout () {
    await $http.post('/auth/signout')
  },
  async getUserData () {
    requireAuthToken()
    const response = await $http.post('/auth/signinwithtoken')
    if (response.data.resFlag) {
      const userJson = new Authentication(response.data.data)
      return userJson
    }
    throw new AuthenticationError('Error')
  },
}

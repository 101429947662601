/**
 * A helper class for error handling based on https://stackoverflow.com/questions/31089801/extending-error-in-javascript-with-es6-syntax-babel
 */

 export default class ExtendableError extends Error {
  constructor (message, ...params) {
    super(message, ...params)
    this.name = this.constructor.name
    if (typeof Error.captureStackTrace === 'function') {
      Error.captureStackTrace(this, this.constructor)
    } else {
      this.stack = (new Error(message)).stack
    }
  }
}

import axios from 'axios'
import { APIServerError } from '../lib/Errors'

const $http = axios.create()

$http.defaults.baseURL = process.env.VUE_APP_API_URL

export function setAuthToken (token) {
  $http.defaults.headers.SessionGuid = token ? `${token}` : null
}

export function requireAuthToken () {
  if ($http.defaults.headers.SessionGuid == null || $http.defaults.headers.SessionGuid.length < 8) {
    throw new APIServerError('Session token not found')
  }
}

export default $http

const Address = require('./Address')
module.exports = class Warehouse {
  constructor (object) {
    this.CustomerId = object.warehouseDB !== undefined ? object.warehouseDB.customerId : object.customerId
    this.AddressId = object.warehouseDB !== undefined ? object.warehouseDB.addressId : object.addressId
    this.Address = object.warehouseDB !== undefined ? new Address(object.warehouseDB.addressData) : new Address(object.addressData)
    this.FormattedAddress = object.warehouseDB !== undefined ? FormattedAddress(object.warehouseDB.addressData) : FormattedAddress(object.addressData)
    this.Id = object.warehouseDB !== undefined ? object.warehouseDB.id : object.id
    this.WarehouseName = object.warehouseDB !== undefined ? object.warehouseDB.warehouseName : object.warehouseName
    this.ContactPerson = object.warehouseDB !== undefined ? object.warehouseDB.contactPerson : object.contactPerson
    this.ContactPhone = object.warehouseDB !== undefined ? object.warehouseDB.contactPhone : object.contactPhone
    this.ContactEmail = object.warehouseDB !== undefined ? object.warehouseDB.contactEmail : object.contactEmail
    this.Status = object.warehouseDB !== undefined ? object.warehouseDB.status : object.status
    if (object.usersList !== undefined) this.UsersList = object.usersList
  }
}

const FormattedAddress = (addressData) => {
  return `${addressData.zipCode}, ${addressData.addressLine}, ${addressData.city}, ${addressData.country}`
}

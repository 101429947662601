import Repository from '@/repositories/RepositoryFactory'

const AddressRepository = Repository.get('address')

export default {
  namespaced: true,
  state: {
    addresses: null,
    selectedAddress: null,
  },
  mutations: {
    GET_ADDRESS (state, payload) {
      state.addresses = payload
    },
    GET_ADDRESS_BY_ID (state, payload) {
      state.selectedAddress = payload
    },
  },
  actions: {
    async getAddress ({ commit }, data) {
      commit('GET_ADDRESS', await AddressRepository.getAddress())
    },
    async updateAddress ({ commit }, body) {
      const res = await AddressRepository.updateAddress(body)
      return res
    },
    async getAddressById ({ commit }, id) {
      commit('GET_ADDRESS_BY_ID', await AddressRepository.getAddressById(id))
    },
    async createAddress ({ commit }, data) {
      const res = await AddressRepository.createAddress(data)
      return res
    },
  },
  getters: {

  },

}

module.exports = class TransporterMetaData {
  constructor (object) {
    this.CustomerId = object.customerId
    this.UserId = object.userId
    this.DriverLicenceNumber = object.driverLicenceNumber
    this.DriverLicenceStateCountry = object.driverLicenceStateCountry
    this.DriverLicenceExpires = object.driverLicenceExpires
    this.EligibilityDocument = object.eligibilityDocument
    this.EligibilityDocumentExpires = object.eligibilityDocumentExpires
    this.Id = object.Id
  }
}

import $http, { requireAuthToken } from '@/plugins/axios'
import { RegionalOfficeError } from '@/lib/Errors'
import RegionalOffice from '@/models/RegionalOffice'

export default {

  async getRegionalOffices () {
    requireAuthToken()
    const response = await $http.get('/regionaloffice')
    if (response.data.resFlag) {
      const userJson = response.data.data.map(regionaloffice => {
        return new RegionalOffice(regionaloffice)
      })
      return userJson
    } else {
      throw new RegionalOfficeError('Unable to fetch offices')
    }
  },
  async getRegionalOfficeById (id) {
    requireAuthToken()
    const response = await $http.get(`/regionaloffice/${id}`)
    if (response.data.resFlag) {
      const userJson = new RegionalOffice(response.data.data)
      return userJson
    } else {
      throw new RegionalOfficeError('Invalid id')
    }
  },
  async createRegionalOffice (body) {
    const response = await $http.post('/regionaloffice', body)
    if (response.data.resFlag) {
      const userJson = response.data
      return userJson
      } else {
      throw new RegionalOfficeError('Error')
    }
  },
  async updateRegionalOffice (body) {
    const response = await $http.put(`/regionaloffice/${body.id}`, body)

    if (response.data.resFlag) {
      const userJson = response.data
      return userJson
    } else {
      throw new RegionalOfficeError('Error')
    }
  },
  async deleteRegionalOffice (id) {
    const response = await $http.delete(`/regionaloffice/${id}`)

    if (response.data.resFlag) {
      return response.data
    } else {
      throw new RegionalOfficeError('Error')
    }
  },
}

import Repository from '@/repositories/RepositoryFactory'

const SecurityCompanyRepository = Repository.get('securitycompany')

export default {
  namespaced: true,
  state: {
    securityCompanies: null,
    selectedSecurityCompany: null,
  },
  mutations: {
    GET_SECURITY_COMPANY (state, payload) {
      state.securityCompanies = payload
    },
    GET_SECURITY_COMPANY_BY_ID (state, payload) {
      state.selectedSecurityCompany = payload
    },
  },
  actions: {
    async getSecurityCompany ({ commit }, data) {
      commit('GET_SECURITY_COMPANY', await SecurityCompanyRepository.getSecurityCompany())
    },
    async createSecurityCompany ({ commit }, data) {
      const res = await SecurityCompanyRepository.createSecurityCompany(data)
      return res
    },
    async getSecurityCompanyById ({ commit }, id) {
      commit('GET_SECURITY_COMPANY_BY_ID', await SecurityCompanyRepository.getSecurityCompanyById(id))
    },
    async updateSecurityCompany ({ commit }, body) {
      const res = await SecurityCompanyRepository.updateSecurityCompany(body)
      return res
    },
  },
  getters: {

  },

}


module.exports = class CustomerOfferedItems {
  constructor (object) {
    this.Id = object.id !== null ? object.id : ''
    this.CustomerId = object.customerId !== null ? object.customerId : ''
    this.ItemName = object.itemName !== null ? object.itemName : ''
    this.ItemDescription = object.itemDescription !== null ? object.itemDescription : ''
    // this.ItemImageUrl = object.itemImageUrl !== null ? object.itemImageUrl : ''
    this.ItemImageUrl = ''
    this.ItemUnit = object.itemUnit !== null ? object.itemUnit : ''
    this.Status = object.status !== null ? object.status : ''
    this.ItemPricePerUnit = object.itemPricePerUnit !== null ? object.itemPricePerUnit : ''
    this.LengthOfBranches = object.assignedBranches !== undefined ? object.assignedBranches.length : 0
    if (object.assignedBranches !== undefined) this.AssignedBranches = object.assignedBranches
  }
}

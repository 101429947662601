import Repository from '@/repositories/RepositoryFactory'

const DealerRepository = Repository.get('dealer')

export default {
  namespaced: true,
  state: {
    dealers: null,
    selectedDealer: null,
  },
  mutations: {
    GET_DEALER (state, payload) {
      state.dealers = payload
    },
    GET_DEALER_BY_ID (state, payload) {
      state.selectedDealer = payload
    },
    // CREATE_CUSTOMER (state, payload) {
    // }
  },
  actions: {
    async getDealer ({ commit }, data) {
      commit('GET_DEALER', await DealerRepository.getDealer())
    },
    async getDealerById ({ commit }, id) {
      commit('GET_DEALER_BY_ID', await DealerRepository.getDealerById(id))
    },
    async createDealer ({ commit }, data) {
      const res = await DealerRepository.createDealer(data)
      return res
    },
    async updateDealer ({ commit }, body) {
      const res = await DealerRepository.updateDealer(body)
      return res
    },
    async deleteDealer ({ commit }, id) {
      const res = await DealerRepository.deleteDealer(id)
      return res
    },
  },
  getters: {

  },
}

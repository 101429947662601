module.exports = class TransporterVehicle {
  constructor (object) {
    this.CustomerId = object.customerId
    this.UserId = object.userId
    this.VehicleNumber = object.vehicleNumber
    this.VehicleRegistrationPlate = object.vehicleRegistrationPlate
    this.VehicleModel = object.vehicleModel
    this.VehicleMake = object.vehicleMake
    this.VehicleType = object.vehicleType
    this.VehicleDescription = object.vehicleDescription
    this.Status = object.status
    this.Id = object.id
  }
}

import $http, { requireAuthToken } from '@/plugins/axios'
import { ConsumerError } from '@/lib/Errors'

export default {
  async getConsumer () {
    requireAuthToken()
    const response = await $http.get('/consumer')
    if (response.data.resFlag) {
      return response.data.data
    }
    throw new ConsumerError('Unable to create Security company')
  },

  async getConsumerById (id) {
    requireAuthToken()
    const response = await $http.get(`/consumer/${id}`)

    if (response.data.resFlag) {
      // TODO: Create response returns using models
      const userJson = response.data.data
      return userJson
    } else {
      throw new ConsumerError('Invalid Id')
    }
  },

  async updateConsumer (body) {
    const response = await $http.put(`/consumer/${body.id}`, body)

    if (response.data.resFlag) {
      const userJson = response.data.data
      return userJson
    } else {
      throw new ConsumerError('Error')
    }
  },

  async createConsumer (body) {
    const response = await $http.post('/consumer', body)

    if (response.data.resFlag) {
      // TODO: Create response returns using models
      return response.data
    } else {
      throw new ConsumerError('Error')
    }
  },
}

import $http, { requireAuthToken } from '@/plugins/axios'
import { RegionalOfficeUserError } from '@/lib/Errors'
import RegionalOfficeUser from '@/models/RegionalOfficeUser'

export default {
  async getRegionalUser () {
    requireAuthToken()
    const response = await $http.get('/regionalofficeuser')

    if (response.data.resFlag) {
      const userJson = response.data.data.map(regionalofficeuser => {
        return new RegionalOfficeUser(regionalofficeuser)
      })
      return userJson
    } else {
      throw new RegionalOfficeUserError('Unable to fetch staff')
    }
  },
  async assignuseridregionalofficeid (body) {
    requireAuthToken()
    const response = await $http.post('regionalofficeuser/assignuseridregionalofficeid', body)

    if (response.data.resFlag) {
      const userJson = response.data.data
      return userJson
    } else {
      throw new RegionalOfficeUserError('Unable to fetch staff')
    }
  },
}

import Repository from '@/repositories/RepositoryFactory'

const TransporterMetaDataRepository = Repository.get('transporterMetaData')

export default {
  namespaced: true,
  state: {
    transporters: null,
    selectedTransporter: null,
  },
  mutations: {
    GET_TRANSPORTER_METADATA (state, payload) {
      state.transporterMetaData = payload
    },
    GET_TRANSPORTER_METADATA_BY_ID (state, payload) {
      state.selectedTransporterMetaData = payload
    },
  },
  actions: {
    async getTransporterMetaData ({ commit }, data) {
      commit('GET_TRANSPORTER_METADATA', await TransporterMetaDataRepository.getTransporterMetaData())
    },
    async getTransporterMetaDataById ({ commit }, id) {
      commit('GET_TRANSPORTER_METADATA_BY_ID', await TransporterMetaDataRepository.getTransporterMetaDataById(id))
    },
    async createTransporterMetaData ({ commit }, data) {
      const res = await TransporterMetaDataRepository.createTransporterMetaData(data)
      return res
    },
    async updateTransporterMetaData ({ commit }, body) {
      const res = await TransporterMetaDataRepository.updateTransporterMetaData(body)
      return res
    },
  },
  getters: {

  },

}

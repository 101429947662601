import $http, { requireAuthToken } from '@/plugins/axios'
import { RoleError } from '@/lib/Errors'
import Role from '@/models/Role'

export default {
  async getRole () {
    requireAuthToken()
    const response = await $http.get('/role')

    if (response.data.resFlag) {
      const userJson = response.data.data.map(role => {
        return new Role(role)
      })
      return userJson
    } else {
      throw new RoleError('Unable to fetch roles')
    }
  },
}

import Repository from '@/repositories/RepositoryFactory'

const TransporterVehicleRepository = Repository.get('transporterVehicle')

export default {
  namespaced: true,
  state: {
    transporterVehicle: null,
    selectedTransporterVehicle: null,
  },
  mutations: {
    GET_TRANSPORTER_VEHICLE (state, payload) {
      state.transporterVehicle = payload
    },
    GET_TRANSPORTER_VEHICLE_BY_ID (state, payload) {
      state.selectedTransporterVehicle = payload
    },
  },
  actions: {
    async getTransporterVehicle ({ commit }, data) {
      commit('GET_TRANSPORTER_VEHICLE', await TransporterVehicleRepository.getTransporterVehicle())
    },
    async getTransporterVehicleById ({ commit }, id) {
      commit('GET_TRANSPORTER_VEHICLE_BY_ID', await TransporterVehicleRepository.getTransporterVehicleById(id))
    },
    async createTransporterVehicle ({ commit }, data) {
      const res = await TransporterVehicleRepository.createTransporterVehicle(data)
      return res
    },
    async updateTransporterVehicle ({ commit }, body) {
      const res = await TransporterVehicleRepository.updateTransporterVehicle(body)
      return res
    },
  },
  getters: {

  },

}

import $http, { requireAuthToken } from '@/plugins/axios'
import { SecurityCompanyError } from '@/lib/Errors'
import SecurityCompany from '@/models/SecurityCompany'

export default {
  async getSecurityCompany () {
    requireAuthToken()
    const response = await $http.get('/securitycompany')

    if (response.data.resFlag) {
      const userJson = response.data.data.map(sc => {
        return new SecurityCompany(sc)
      })
      return userJson
    } else {
      throw new SecurityCompanyError('Unable to create Security company')
    }
  },

  async getSecurityCompanyById (id) {
    requireAuthToken()
    const response = await $http.get(`/securitycompany/${id}`)

    if (response.data.resFlag) {
      // TODO: Create response returns using models
      const userJson = response.data.data
      return userJson
    } else {
      throw new SecurityCompanyError('Invalid Id')
    }
  },

  async updateSecurityCompany (body) {
    const response = await $http.put(`/securitycompany/${body.id}`, body)

    if (response.data.resFlag) {
      const userJson = response.data.data
      return userJson
    } else {
      throw new SecurityCompanyError('Error')
    }
  },

  async createSecurityCompany (body) {
    const response = await $http.post('/securitycompany', body)

    if (response.data.resFlag) {
      // TODO: Create response returns using models
      return response.data
    } else {
      throw new SecurityCompanyError('Error')
    }
  },
}

import Repository from '@/repositories/RepositoryFactory'

const WarehouseRepository = Repository.get('warehouse')

export default {
  namespaced: true,
  state: {
    warehouses: null,
    selectedWarehouse: null,
  },
  mutations: {
    GET_WAREHOUSE (state, payload) {
      state.warehouses = payload
    },
    GET_WAREHOUSE_BY_ID (state, payload) {
      state.selectedWarehouse = payload
    },
  },
  actions: {
    async getWarehouse ({ commit }, data) {
      commit('GET_WAREHOUSE', await WarehouseRepository.getWarehouse())
    },
    async getWarehouseById ({ commit }, id) {
      commit('GET_WAREHOUSE_BY_ID', await WarehouseRepository.getWarehouseById(id))
    },
    async createWarehouse ({ commit }, data) {
      const res = await WarehouseRepository.createWarehouse(data)
      return res
    },
    async updateWarehouse ({ commit }, body) {
      const res = await WarehouseRepository.updateWarehouse(body)
      return res
    },
    async deleteWarehouse ({ commit }, id) {
      const res = await WarehouseRepository.deleteWarehouse(id)
      return res
    },
  },
  getters: {

  },

}

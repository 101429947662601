import Repository from '@/repositories/RepositoryFactory'

const BranchOfficeRepository = Repository.get('branchOffice')

export default {
  namespaced: true,
  state: {
    branchOffices: null,
    selectedBranchOffice: null,
  },
  mutations: {
    GET_BRANCHOFFICE (state, payload) {
      state.branchOffices = payload
    },
    GET_BRANCH_OFFICES_BY_ID (state, payload) {
      state.selectedBranchOffice = payload
    },
  },
  actions: {
    async getBranchOffice ({ commit }, data) {
      commit('GET_BRANCHOFFICE', await BranchOfficeRepository.getBranchOffice())
    },
    async getBranchOfficesById ({ commit }, id) {
      commit('GET_BRANCH_OFFICES_BY_ID', await BranchOfficeRepository.getBranchOfficeById(id))
    },
    async createBranchOffice ({ commit }, data) {
      const res = await BranchOfficeRepository.createBranchOffice(data)
      return res
    },
    async updateBranchOffice ({ commit }, data) {
      const res = await BranchOfficeRepository.updateBranchOffice(data)
      return res
    },
    async deleteBranchOffice ({ commit }, id) {
      const res = await BranchOfficeRepository.deleteBranchOffice(id)
      return res
    },
  },
  getters: {

  },

}

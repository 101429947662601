import $http, { requireAuthToken } from '@/plugins/axios'
import { DeliveryOrderError } from '@/lib/Errors'
import DeliveryOrder from '@/models/DeliveryOrder'

export default {
  async getDeliveryOrder () {
    requireAuthToken()
    const response = await $http.get('/deliveryorder')
    if (response.data.resFlag) {
      const userJson = response.data.data.map(deliveryOrder => {
        return new DeliveryOrder(deliveryOrder)
      })

      return userJson
    } else {
      throw new DeliveryOrderError('Error retrieving delivery orders')
    }
  },
  async getDeliveryOrderById (id) {
    requireAuthToken()
    const response = await $http.get(`/deliveryorder/${id}`)
    if (response.data.resFlag) {
      const userJson = response.data.data
      return userJson
    } else {
      throw new DeliveryOrderError('Invalid delivery order id')
    }
  },
  async createDeliveryOrder (body) {
    const response = await $http.post('/deliveryorder', body)

    if (response.data.resFlag) {
      const userJson = response.data
      return userJson
    } else {
      throw new DeliveryOrderError('Error creating delivery order')
    }
  },
  async updateDeliveryOrder (body) {
    const response = await $http.put(`/deliveryorder/${body.id}`, body)

    if (response.data.resFlag) {
      const userJson = response.data
      return userJson
    } else {
      throw new DeliveryOrderError('Error updating the order')
    }
  },
  async deleteDeliveryOrder (id) {
    const response = await $http.delete(`/deliveryorder/${id}`)

    if (response.data.resFlag) {
      return response.data
    } else {
      throw new DeliveryOrderError('Error deleting the delivery order')
    }
  },
}

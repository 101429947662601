import $http, { requireAuthToken } from '@/plugins/axios'
import { AddressError } from '@/lib/Errors'
import Address from '@/models/Address'

export default {
  async getAddress () {
    requireAuthToken()

    const response = await $http.get('/address')

    if (response.data.resFlag) {
      const userJson = response.data.data.map(address => {
        return new Address(address)
      })
      return userJson
    }

    throw new AddressError('Unable to fetch addresses')
  },
  async getAddressById (id) {
    requireAuthToken()

    const response = await $http.get(`/address/${id}`)

    if (response.data.resFlag) {
      const userJson = response.data.data
      return userJson
    }

    throw new AddressError('Unable to fetch selected address')
  },

  async createAddress (body) {
    const response = await $http.post('/address', body)
    if (response.data.resFlag) {
      return response.data
    } else {
      throw new AddressError('Error')
    }
  },
  async updateAddress (body) {
    const response = await $http.put(`/address/${body.Id}`, body)

    if (response.data.resFlag) {
      const userJson = response.data
      return userJson
    } else {
      throw new AddressError('Error')
    }
  },
}

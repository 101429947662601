module.exports = class Address {
  constructor (object) {
    this.Id = object.id
    this.AddressType = object.addressType !== null ? object.addressType : ''
    this.AddressLine = object.addressLine !== null ? object.addressLine : ''
    this.ZipCode = object.zipCode !== null ? object.zipCode : ''
    this.City = object.city !== null ? object.city : ''
    this.State = object.state !== null ? object.state : ''
    this.Country = object.country !== null ? object.country : ''
    this.Longitude = object.longitude !== null ? object.longitude : ''
    this.Latitude = object.latitude !== null ? object.latitude : ''
    this.CustomerId = object.customerId !== null ? object.customerId : ''
    this.formattedAddress = getFormattedAddress(object)
  }
}

const getFormattedAddress = (object) => {
  return `${object.addressLine}, ${object.city}, ${object.country}, ${object.zipCode}`
}

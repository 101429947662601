import authentication from './modules/authentication'
import user from './modules/user'
import securityCompany from './modules/securityCompany'
import customer from './modules/customer'
import address from './modules/address'
import role from './modules/role'
import customerOfferedItems from './modules/customerItems'
import consumer from './modules/consumer'
import contract from './modules/contract'
import dealer from './modules/dealer'
import verifier from './modules/verifier'
import consumeruser from './modules/consumerUser'
import dealeruser from './modules/dealerUser'
import warehouse from './modules/warehouse'
import transporterMetaData from './modules/transporterMetaData'
import warehouseuser from './modules/warehouseUser'
import transporterVehicle from './modules/transporterVehicle'
import transporterSecurities from './modules/transporterSecurities'
import regionaloffice from './modules/regionaloffice'
import deliveries from './modules/deliveries'
import order from './modules/order'
import regionalofficeuser from './modules/regionalofficeuser'
import branchOffice from './modules/branchOffice'
import branchofficeuser from './modules/branchofficeuser'
import coiBranchesPivot from './modules/coiBranchesPivot'
import deliveryOrder from './modules/deliveryOrder'

export default {
  authentication,
  user,
  securityCompany,
  customer,
  address,
  role,
  customerOfferedItems,
  consumer,
  contract,
  dealer,
  verifier,
  consumeruser,
  dealeruser,
  warehouse,
  transporterMetaData,
  warehouseuser,
  transporterVehicle,
  transporterSecurities,
  regionaloffice,
  deliveries,
  order,
  regionalofficeuser,
  branchOffice,
  branchofficeuser,
  coiBranchesPivot,
  deliveryOrder,
}

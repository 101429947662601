import $http, { requireAuthToken } from '@/plugins/axios'
import { UserError } from '@/lib/Errors'
import User from '@/models/User'
import UserCustomerAdmin from '../../models/UserCustomerAdmin'

export default {
  async getUser () {
    requireAuthToken()
    const response = await $http.get('/user')
    if (response.data.resFlag) {
      const userJson = response.data.data.map(user => {
        return new User(user)
      })

      return userJson
    } else {
      throw new UserError('Error retrieving users')
    }
  },
  async getUserById (id) {
    requireAuthToken()
    const response = await $http.get(`/user/${id}`)
    if (response.data.resFlag) {
      const userJson = new User(response.data.data)
      return userJson
    } else {
      throw new UserError('Invalid id')
    }
  },
  async getUserByEmail (email) {
    requireAuthToken()
    const response = await $http.get('/user/getuserbyemail', { headers: { Email: email } })

    if (response.data.resFlag) {
      const userJson = response.data.data
      return userJson
    } else {
      throw new UserError('Invalid email')
    }
  },
  async createUser (body) {
    const response = await $http.post('/user', body)

    if (response.data.resFlag) {
      const userJson = response.data
      return userJson
    } else {
      throw new UserError('Error')
    }
  },
  async updateUser (body) {
    const response = await $http.put(`/user/${body.id}`, body)
    if (response.data.resFlag) {
      const userJson = response.data
      return userJson
    } else {
      throw new UserError('Error')
    }
  },
  async deleteUser (id) {
    const response = await $http.delete(`/user/${id}`)

    if (response.data.resFlag) {
      return response.data
    } else {
      throw new UserError('Error deleting the user')
    }
  },

  async changepassword (data) {
    const response = await $http.post('/user/changemypassword', data)

    if (response.data.resFlag) {
      return response.data
    } else {
      throw new UserError('Error changing the password')
    }
  },
  async changePasswordValidationToken (data) {
    const response = await $http.post('/user/changemypasswordwithvalidationtoken', data)

    if (response.data.resFlag) {
      return response.data
    } else {
      throw new UserError('Error changing the password')
    }
  },
  async getNotAssociatedCustomerAdmins () {
    const response = await $http.get('/user/getnotassociatedcustomeradmins')

    if (response.data.resFlag) {
      const userJson = response.data.data.map(user => {
        return new UserCustomerAdmin(user)
      })
      return userJson
    } else {
      throw new UserError('Could not get customer admins!')
    }
  },
  async getmyuserprofilephoto () {
    const response = await $http.get('/user/getmyuserprofilephoto')
    if (response.data.resFlag) {
      const userJson = response.data
      return userJson
    } else {
      throw new UserError('Could not get image!')
    }
  },
  async uploadmyuserprofilephoto (formData) {
    const response = await $http.post('/user/uploadmyuserprofilephoto', formData,
    {
      headers: { 'Content-Type': 'multipart/form-data' },
    })
    if (response.data.resFlag) {
      const userJson = response.data
      return userJson
    } else {
      throw new UserError('Could not upload image!')
    }
  },
}
